/* ------------------------------
  Tailwind
------------------------------ */

@tailwind base;
@tailwind components;
@tailwind utilities;


/* ------------------------------
  Fonts
------------------------------ */

/* 400 */
@font-face {
  font-family: "PlayfairDisplay Regular";
  src: url("./assets/fonts/PlayfairDisplay-Regular.ttf");
}

/* 400 */
@font-face {
  font-family: "PlayfairDisplay Italic";
  src: url("./assets/fonts/PlayfairDisplay-Italic.ttf");
}

/* 200 */
@font-face {
  font-family: "DMSans ExtraLight";
  src: url("./assets/fonts/DMSans-ExtraLight.ttf");
}

/* 300 */
@font-face {
  font-family: "DMSans Light";
  src: url("./assets/fonts/DMSans-Light.ttf");
}

/* 400 */
@font-face {
  font-family: "DMSans Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf");
}

/* 700 */
@font-face {
  font-family: "DMSans Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf");
}


/* ------------------------------
  Global
------------------------------ */

html {
  /* this makes sure the padding and the border is included in the box sizing */
  /* box-sizing: border-box;
  overflow-x: hidden; */
  /* scroll-behavior: smooth !important; */
}

html body {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  background-color: #000;
}


/* ------------------------------
  Form
------------------------------ */

input, textarea, select {
  background-color: transparent;
  border: 0px solid;
  border-radius: 0;
}

select {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

textarea:focus, select:focus, input:focus{
  outline: none;
}

*:focus {
  outline: none;
}

input[type=checkbox] {
  accent-color: #000;
}

input[type="search"] {
  -webkit-appearance: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A6A19A;
  opacity: 1; /* Firefox */
}
